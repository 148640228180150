@import "includes";

.vb-loader-table {
  border: 1px solid #E4E4E7;

  &:before {
    display: block;
    content: "";
    height: 98px;

    border-bottom: 1px solid #E4E4E7;
  }

  &:after {
    display: block;
    content: "";
    height: 64px;

    border-top: 1px solid #E4E4E7;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 60px;

    margin: 0 30px 0 20px;
    padding: 0 30px;

    &:not(:last-child) {
      border-bottom: 1px solid #E4E4E7;
    }

  }
}

.vb-line {
  height: 10px;
  width: 12%;

  background: #ECECEC;

}

.vb-loader-counter {
  display: flex;
  align-items: center;

  border: 1px solid #E4E4E7;

  height: 147px;

  .counter {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    height: 85px;

    &:first-child {
      border-right: 1px solid #E4E4E7;
    }
  }
}

.vb-loader-list {
  border: 1px solid #E4E4E7;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;

    height: 70px;

    margin: 0 30px 0 20px;
    padding: 0 30px;

    &:not(:last-child) {
      border-bottom: 1px solid #E4E4E7;
    }

  }
}

.vb-loader-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  border: 1px solid #E4E4E7;

  .row {
    display: flex;
    align-items: center;
    gap: 80px;

    padding: 0 30px;
  }
}

.vb-loader-policies {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  border: 1px solid #E4E4E7;

  .row {
    display: flex;
    align-items: center;
    gap: 80px;

    padding: 0 30px;
  }
}

.vb-square {
  height: 16px;
  width: 16px;

  background: #ECECEC;
}

.vb-group {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.vb-group-horizontal {
  display: flex;
  gap: 10px;
  align-items: center;
}

.f1 {
  flex: 1;
}

.w50 {
  width: 50%;
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}
