@import "includes";

:root {
  --color-white: #fff;
  --color-white-hover: rgba(242, 245, 250, 0.5);
  --color-white-active: rgba(227, 234, 245, 0.5);

  --color-black: #000;

  --color-transparent: #00000000;

  --color-primary-blue: #001F5C;
  --color-primary-blue-hover: #011B4E;
  --color-primary-blue-active: #001641;

  --color-red: #D03234;
  --color-red-hover: #B81517;
  --color-red-active: #A41A1B;

  --color-light-blue: #0C4F87;

  --color-input-stroke: #CFD6E4;

  --color-text-default: #8391A1;

  --color-grey-light-background: #F9F9F9;
}

