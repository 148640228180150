@import "includes";

.vb-dialog-w656 {
  .vb-dialog {
    @include desktop {
      width: 656px;
    }
  }
}

.vb-dialog-full-width-buttons {
  .vb-dialog {

    .footer.buttons {
      app-button {
        flex: 1;

        button {
          padding: 20px;
        }
      }
    }
  }
}

.vb-dialog-full-width-buttons-mobile {
  .vb-dialog {
    .footer.buttons {
      app-button {
        @include mobile {
          flex: 1;
        }
      }
    }
  }
}

.vb-dialog-dock-bottom {
  .vb-dialog {
    @include mobile {
      position: fixed;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      place-items: initial;

      top: initial;
      left: 0;
      right: 0;
      bottom: 0;

      height: initial;

      transform: initial;
    }
  }
}

.vb-dialog {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 40px;

  display: grid;
  place-items: center stretch;

  background: var(--color-white);

  width: 100%;

  @include desktop {
    width: 425px;

    max-height: 100vh;
    overflow: auto;
  }

  @include mobile {
    height: 100vh;
    overflow: scroll;

    padding: 40px 25px 25px 25px;
  }

  .header {
    font-size: 34px;
    line-height: 44px;

    .hint {
      margin-top: 10px;

      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #8391A1;
    }

    .close {
      position: absolute;

      top: 20px;
      right: 15px;

      cursor: pointer;
    }
  }

  > .content {
    margin-top: 30px;

    .column {
      display: flex;
      flex-direction: column;
    }
  }

  .footer.buttons {
    justify-self: stretch;

    display: flex;
    // justify-content: flex-end;

    margin-top: 34px;

    app-button {
      // flex: 1;
    }
    app-button:not(:first-child) {
      margin-left: 14px;
    }
  }

  &.isLoading {
    > .content, > .footer, > .debug {
      opacity: 0;
    }
  }
}

.vb-dialog-cols-2 {
  .vb-dialog {

    @include desktop {
      width: 926px;
    }
  }

  .vb-dialog > .content {
    display: flex;
    gap: 40px;

    > * {
      flex: 1;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .footer.buttons {

    @include desktop {
      justify-self: flex-end;
    }

    app-button {
      min-width: 164px;
    }
  }
}

.heading {
  color: var(--color-black);

  font-family: 'Signifier';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 44px;
}

.heading-2 {
  color: var(--color-black);

  font-family: 'Signifier';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
}
