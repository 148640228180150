@import "includes";

.vi-table {

  th {
    position: sticky;
    top: 0;

    padding: 25px 50px 20px 0;

    text-align: left;

    font-family: 'Work Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;

    color: #8391A1;
    background: var(--color-white);

    user-select: none;

    &.sortBy {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &.arrow-desc:after {
      position: relative;
      top: -3px;
      right: -6px;

      display: inline-block;
      content: "";

      width: 0;
      height: 0;
      border-top: 8px solid #8391A1; 
      border-right: 8px solid transparent;

      transform: rotate(-135deg);
    }

    &.arrow-asc:after {
      position: relative;
      top: 2px;
      right: -6px;

      display: inline-block;
      content: "";

      width: 0;
      height: 0;
      border-top: 8px solid #8391A1; 
      border-right: 8px solid transparent;

      transform: rotate(45deg);
    }
  }

  th, td {
    border-bottom: 1px solid #E4E4E7;

    &:not(.last) {
      padding: 28px 50px 26px 0;
    }

    &.first {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  td {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  tr.clickable:hover td {
    background: #F9F9F9;
    cursor: pointer;
  }
}
