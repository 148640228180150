@import "includes";

.vb-mobile-only {
  @include desktop {
    display: none !important;
  }
}

.vb-desktop-only {
  @include mobile {
    display: none !important;
  }
}

.vb-page-with-dock {
  padding-bottom: 64px;
}

.vb-dock {
  position: fixed;

  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  width: 100%;

  padding: 12px;
  margin: 0 auto;

  @include desktop {
    padding: 12px 0;
    max-width: 1072px;
  }

  app-button:not(:first-of-type) {
    margin-left: 12px;
  }
}

.vb-form {
  // width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // gap: 32px;
  @include desktop {
    // min-width: 1072px;
    // max-width: 1072px;
  }


  @include mobile {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
}

.vb-form-subgroup {
  max-width: 520px;
  flex: 1 1 auto;
  flex-basis: calc(50% - 32px);

  @include desktop {
    min-width: 500px;
  }

  @include mobile {
    width: 100%;
  }
}

.vb-form-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-gray32);
}


.vb-form-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  &:not(:first-of-type) {
    margin-top: 20px;
  }

  &.phone {
    flex-direction: row;
    gap: 12px;

         * {
           flex: 4;
         }
         app-text-input:first-child {
           flex: 1.2;
         }
       }
     }

.vb-form-field-group {
  display: flex;

  margin-top: 20px;

  .vb-form-field-column {
    flex: 1;

    margin-top: 0px;

    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }
}

.vb-form-field-error {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-family: 'WorkSans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  margin-top: 10px;

  &:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;

    margin-right: 6px;

    background: var(--color-red);
  }
}

.vb-form-field-textarea {
  flex: none;
  flex-grow: 0;
}

.vb-auth-page {
  min-height: 100%;

  display: flex;
  flex-direction: column;

  @include desktop {
    align-items: center;
  }

  .vb-form {
    display: flex;
    flex-direction: column;

    margin: 0 25px;

    min-height: 100%;

    @include desktop {
      max-width: 340px;
      width: 100%;
    }
  }

  .vb-form-header {
    font-size: 46px;
    font-weight: 500;
    line-height: 52.9px;

    align-self: flex-start;
  }

  .vb-form-header-2 {
    font-size: 34px;
    font-weight: 500;
    line-height: 44px;

    align-self: flex-start;
  }

  .vb-form-header-goback {
    display: flex;
    align-items: center;

    font-family: 'WorkSans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;

    align-self: flex-start;

    margin-bottom: 15px;

    color: var(--color-primary-blue);

    cursor: pointer;
    user-select: none;

    &:hover {
      color: #011B4E;
    }

    &:before {
      display: block;
      content: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 12.5H5' stroke='%23001F5C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 19.5L5 12.5L12 5.5' stroke='%23001F5C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      height: 25px;
      margin-right: 8px;
    }
  }

  .vb-hint {
    align-self: stretch;

    font-family: 'WorkSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: var(--color-text-default);
  }

  .vb-buttons {
    align-self: stretch;

    display: flex;
    flex-direction: column;
  }

  .vb-bottom-hint {
    position: absolute;
    bottom: 58px;

    font-family: 'WorkSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;

    text-align: center;

    color: #8391A1;

    @include desktop { 
      max-width: 340px;
    }

    @include mobile {
      padding: 0 25px;

      left: 0;
      right: 0;
    }

    span {
      color: var(--color-primary-blue);

      text-decoration: underline;
      cursor: pointer;

      &.isSubmittingNewCode {
        color: var(--color-text-default);
        cursor: not-allowed;

        &:after {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;

          margin-left: 6px;

          border: 2px solid var(--color-text-default);
          border-top: 2px solid #00000000;

          animation: spinner-circular-anim linear infinite 1000ms;
        }
      }
    }
  }
}

@keyframes spinner-circular-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
