@mixin dark {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 768px) {
    @content;
  }
};

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
};

@mixin border-radius {
  border-radius: 4px;
}

