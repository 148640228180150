
$font-sizes: (
  "-20": 20px,
  "-24": 24px,
  "-30": 30px,
  "-34": 34px,
  "-46": 46px,
);

$font-size-line-heights: (
  "-12": 16px,
  "-14": 20px,
  "-16": 24px,
  "-20": 28px,
  "-24": 32px,
  "-32": 44px,
  "-40": 52px,
  "-48": 60px,
);

$font-weights: (
  "": 500,
  "-bold": 700,
);

@each $size-name, $size in $font-sizes {
  @each $weight-name, $weight in $font-weights {
    // @debug text#{$size-name}#{$weight-name};
    %text#{$size-name}#{$weight-name} {
      font-size: $size;
      font-weight: $weight;
      // line-height: map-get($font-size-line-heights, $size-name);
    }
  }
}

