@import "~@angular/cdk/overlay-prebuilt.css";

@import "includes";
@import "colors.global";
@import "layout.global";
@import "loader.global";
@import "dialog.global";
@import "tables.global";

@font-face {
  font-family: Signifier;
  font-weight: 500;
  src: url('styles/fonts/signifier-medium.woff2');
}

@font-face {
  font-family: Signifier;
  font-weight: 300;
  src: url('styles/fonts/signifier-light.woff2');
}

@font-face {
  font-family: WorkSans;
  src: url('styles/fonts/WorkSans-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Work Sans';
  src: url('styles/fonts/WorkSans-VariableFont_wght.ttf');
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;

  font-family: Signifier;
  font-weight: 500;
  font-style: normal;
}

body.no-scroll {
  touch-action: none;
  -ms-touch-action: none;
  overflow: hidden;
  position: relative;
}

// Global typography classes
@each $size-name, $size in $font-sizes {
  @each $weight-name, $weight in $font-weights {
    .text#{$size-name}#{$weight-name} {
      @extend %text#{$size-name}#{$weight-name};
    }
  }
}
